import type { MarketingConfig } from "~/types";

export const marketingConfig: MarketingConfig = {
	mainNav: [
		{
			title: "Features",
			href: "/#features",
			hide: true,
		},
		{
			title: "Pricing",
			href: "/pricing",
			hide: true,
		},
		{
			title: "Blog",
			href: "/blog",
			hide: true,
		},
		{
			title: "Documentation",
			href: "/docs",
			hide: true,
		},
	],
};
