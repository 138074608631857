import { Link, useLocation } from "@remix-run/react";
import { marketingConfig } from "~/config/public";
import { cn } from "~/lib/utils";
import { MainNav } from "./layouts/main-nav";
import ThemeSwitcher from "./theme-switcher";
import { buttonVariants } from "./ui/button";
import { AppRoutes } from "~/utils/routes";
import { useSession } from "~/contexts/session";
import { UserNav } from "./layouts/user-nav";

export function Header() {
	const { pathname } = useLocation();
	const { isAuthenticated, user } = useSession();

	return (
		<header className="sticky top-0 z-40 w-full backdrop-blur-sm bg-background/80 border-b border-border transition-colors duration-300">
			<div className="container mx-auto">
				<div className="flex h-16 items-center justify-between py-4">
					<MainNav items={marketingConfig.mainNav} />
					<nav className="flex items-center space-x-4">
						{isAuthenticated ? (
							<>
								{pathname === AppRoutes.INDEX && (
									<Link
										to={AppRoutes.PROFILE_SELECT}
										className={cn(
											buttonVariants({ variant: "ghost", size: "sm" }),
											"text-foreground hover:text-foreground hover:bg-muted"
										)}
									>
										Dashboard
									</Link>
								)}
								<UserNav />
							</>
						) : (
							<Link
								to={AppRoutes.LOGIN}
								className={cn(
									buttonVariants({ variant: "ghost", size: "sm" }),
									"text-foreground hover:text-foreground hover:bg-muted"
								)}
							>
								Login
							</Link>
						)}
						<ThemeSwitcher />
					</nav>
				</div>
			</div>
		</header>
	);
}
